<template>
    <v-dialog v-model="dialog_ref" max-width="768" hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-card-title class="text-h5 blue white--text">
                Agregar nuevo reporte
                <v-spacer></v-spacer>
                <v-btn icon outlined class="white--text" @click="emitClose">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-container fluid>
                <v-row>
                    <v-col>
                        <v-text-field v-model="tarea" :rules="rules" counter="80" label="Actividad"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field disabled v-model="noticket" label="# de reporte"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="notas">Notas adicionales</label>
                        <wysiwyg #notas v-model="notas" />
                    </v-col>
                </v-row>
                <v-row class="text-center">
                    <v-col cols="6">
                        <v-btn color="danger" @click="emitClose()"> CANCELAR </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn color="primary" @click="add()"> AGREGAR </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        dialog_ref: Boolean,
        lastTicket: String
    },
    data() {
        return {
            tarea: null,
            noticket: null,
            notas: null,
            rules: [v => v ? v.length <= 80 : null || 'Max 80 caracteres'],
        };
    },
    methods: {
        emitClose() {
            this.$emit("onClose", {
                action: false,
                data: null,
            });
        },
        add() {
            this.$emit("onClose", {
                action: true,
                data: {
                    tarea: this.tarea,
                    noticket: this.noticket,
                    notas: this.notas,
                    estatus: '6ce798f6-3f80-4a81-8e41-4602f442fcd2'
                },
            });
        },
    },
    created() {
        if (this.lastTicket != 'no_ticket') {
            let number = Number(this.lastTicket.split('-')[1]);
            let nextTicket = number + 1;
            this.noticket = 'MC-' + nextTicket;
        }
        else {
            this.noticket = 'MC-1';
        }
    },
    watch: {
        lastTicket(val) {
            if (this.lastTicket != 'no_ticket') {
                let number = Number(this.lastTicket.split('-')[1]);
                let nextTicket = number + 1;
                this.noticket = 'MC-' + nextTicket;
            }
            else {
                console.log('enter here');
                this.noticket = 'MC-1';
            }
        }
    }
};
</script>