<template>
  <v-dialog v-model="dialog_ref" max-width="768" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-card-title class="text-h5 blue white--text">
        Anexos y Notas
        <v-spacer></v-spacer>
        <v-btn icon outlined class="white--text" @click="emitClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs v-model="tab" fixed-tabs background-color="blue" dark>
        <v-tab href="#notas">Notas</v-tab>
        <v-tab href="#anexos">Anexos</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="notas">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      <!--<div v-html="this.notas"></div>-->
                      <wysiwyg #notas v-model="notas" />
                      <v-btn color="primary" @click="saveNotes()">GUARDAR</v-btn>
                    </v-list-item-title>
                    <v-list-item-subtitle>Última actualización :
                      {{ lastUpdate }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="anexos">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-data-table :headers="headers" :items="anexos" :items-per-page="5" class="elevation-1" :footer-props="{
                  itemsPerPageText: 'El. por página',
                  itemsPerPageAllText: 'Todo',
                  itemsPerPageOptions: ippoptions,
                }" no-results-text="No hay resultados" no-data-text="No hay tareas para mostrar">
                  <template v-slot:item.wixFS="{ item }">
                    <v-btn @click="download(item)"> DESCARGAR </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "date-fns/format";

export default {
  props: {
    dialog_ref: Boolean,
    data: Object,
  },
  data() {
    return {
      endpoint: "https://megacomp.mx/_functions/",
      anexos: [],
      notas: "",
      tab: "notas",
      lastUpdate: null,
      headers: [
        {
          text: "Anexo",
          value: "label",
          align: "center",
        },
        {
          text: "Descargar",
          value: "wixFS",
          align: "center",
        },
      ],
      ippoptions: [5, 10, -1],
      wixMedia: "https://static.wixstatic.com/media/",
      wixDocument:
        "https://8b7eef41-7fbb-440b-928a-a442878112a3.usrfiles.com/ugd/",
      fileInput: null
    };
  },
  methods: {
    emitClose() {
      this.$emit("onClose", {
        action: false,
        data: null
      });
    },
    async download(item) {
      if (item.type == "media") {
        window.open(this.wixMedia + item.wixFS, "_blank");
      } else if (item.type == "document") {
        window.open(this.wixDocument + item.wixFS, "_blank");
      }
    },
    saveNotes() {
      this.$emit("onClose", {
        action: true,
        data: {
          notas: this.notas
        },
      });
    }
  },
  watch: {
    data(newVal) {
      // Dialog closed
      if (newVal == null) {
        return;
      }

      // Dialog opened
      this.lastUpdate = format(new Date(this.data._updatedDate), "dd-MM-yyyy");
      this.notas = this.data.notas;
      //extract documents name
      if (this.data.anexos) {
        for (let anexo of this.data.anexos) {
          let fileName = null;
          let wixFS = null;
          let mediaType = null;
          if (anexo.indexOf("wix:image") > -1) {
            let first = anexo.split("/")[4];
            fileName = first.split("#")[0];
            wixFS = anexo.split("/")[3];
            mediaType = "media";
          } else if (anexo.indexOf("wix:document") > -1) {
            fileName = anexo.split("/")[5];
            wixFS = anexo.split("/")[4];
            mediaType = "document";
          } else {
            continue;
          }
          this.anexos.push({
            label: fileName,
            wixFS: wixFS,
            type: mediaType,
          });
        }
      }

    },
  },
};
</script>