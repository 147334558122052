<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" md="6"><h2>Megacomp Reportes</h2></v-col>
      <v-col cols="12" md="6">
        <v-btn icon @click="openAddDialog()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        &nbsp;
        <v-btn icon @click="getTasks">
          <v-icon>mdi-update</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateInit"
              label="Fecha de inicio"
              prepend-icon="mdi-calendar"
              readonly
              persistent-hint
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateInit"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="4">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateEnd"
              label="Fecha fin"
              prepend-icon="mdi-calendar"
              readonly
              persistent-hint
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateEnd"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-btn @click="filtroFecha()">FILTRAR</v-btn>
        <v-btn @click="getData()">RESETEAR</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="search"
          label="Buscar"
          :loading="searchLoading"
          @input="handleSearchDebounce()"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          auto-select-first
          chips
          clearable
          deletable-chips
          small-chips
          v-model="filterAsignados"
          :items="personal"
          item-text="nombre"
          item-value="_id"
          label="Filtrar por asignado"
          @change="filterAsignado()"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          auto-select-first
          chips
          clearable
          deletable-chips
          small-chips
          v-model="filterClientes"
          :items="clientes"
          item-text="fullName"
          item-value="_id"
          label="Filtrar por cliente"
          @change="filterCliente()"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="2" class="text-center" style="cursor: pointer">
        <export-excel :data="excelData">
          <v-btn icon v-tooltip.left-start="'Para exportar por fecha, recuerda filtrar primero los registros'">
            <v-icon>download</v-icon>
          </v-btn>
        </export-excel>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="4"
        xs="6"
        v-for="(reporte, index) of tasks"
        :key="reporte._id"
      >
        <v-card class="w-100 px-4">
          <v-card-title>{{ reporte.noticket }}</v-card-title>
          <v-card-subtitle>{{ reporte.tarea }}</v-card-subtitle>
          <p class="px-4" v-if="reporte.fechaDeAtencion">
            Atender el día: {{ reporte.fechaDeAtencion | date }}
          </p>
          <p class="px-4" v-if="!reporte.fechaDeAtencion">
            Atender el día: No definida
          </p>
          <v-menu
            v-model="reporte.menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="reporte.fechaDeAtencion"
                label="Fecha de atención"
                prepend-icon="mdi-calendar"
                readonly
                persistent-hint
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reporte.fechaDeAtencion"
              @input="log(reporte, index)"
            ></v-date-picker>
          </v-menu>
          <v-autocomplete
            class="px-4"
            auto-select-first
            chips
            clearable
            deletable-chips
            multiple
            small-chips
            v-model="reporte.asignados"
            :items="personal"
            item-text="nombre"
            item-value="_id"
            label="Personal asignado"
          >
          </v-autocomplete>
          <v-autocomplete
            class="px-4"
            auto-select-first
            clearable
            v-model="reporte.cliente"
            :items="clientes"
            item-text="fullName"
            item-value="_id"
            label="Cliente"
          ></v-autocomplete>
          <v-select
            class="px-4"
            :items="estatuses"
            v-model="reporte.estatus"
            item-text="label"
            item-value="value"
            label="Estatus"
          >
          </v-select>
          <v-card-actions>
            <v-btn icon @click="openSaveDialog(reporte)">
              <v-icon>save</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon @click="openAnexosNotas(reporte)">
              <v-icon>folder</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <ReporteButton :data="resolveReporteData(reporte)" />
            <v-spacer></v-spacer>
            <v-btn icon @click="openAsignadosMailDialog(reporte)">
              <v-icon>mail</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <SaveDialog
      :dialog_ref="saveDialog"
      :data="selectedForSave"
      @onClose="handleSave"
    />
    <AddDialog
      :dialog_ref="addDialog"
      @onClose="handleAdd"
      :lastTicket="lastTicket"
    />
    <AnexosDialog
      :dialog_ref="anexosDialog"
      :data="selectedForAnexos"
      @onClose="closeAnexosDialog"
    />

    <AsignadosMail
      :dialog_ref="notAsignadosDialog"
      :data="{
        tarea: selectedForAsignadosMail,
        personal: personal,
        clientes: clientes,
      }"
      @onClose="closeAsignadosDialog"
    />

    <!--<DeleteDialog :dialog_ref="deleteDialog" :data="selectedForDelete" @onOpen="deleteDialog = true"
      @onClose="handleDelete" />-->
    <v-overlay :value="loading" z-index="7">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import AnexosDialog from "@/components/AnexosDialog.vue";
import SaveDialog from "@/components/SaveDialog.vue";
import DeleteDialog from "../components/DeleteDialog.vue";
import AsignadosMail from "../components/AsignadosMail.vue";
import ReporteButton from "../components/GenerarReporte.vue";
import AddDialog from "../components/AddDialog.vue";
import { format } from "date-fns";
import { convert } from "html-to-text";

export default {
  name: "Home",
  components: {
    AnexosDialog,
    SaveDialog,
    DeleteDialog,
    AsignadosMail,
    ReporteButton,
    AddDialog,
  },
  data() {
    return {
      loading: false,
      endpoint: "https://megacomp.mx/_functions/",
      tasks: [],
      asignados: [],
      personal: [],
      clientes: [],
      estatuses: [
        { label: "PENDIENTE", value: "6ce798f6-3f80-4a81-8e41-4602f442fcd2" },
        { label: "EN PROGRESO", value: "d95a0169-6268-4166-b355-2683087007ef" },
        { label: "TERMINADO", value: "55ed3b8f-05f5-4e5d-8e9f-92474318e231" },
      ],
      anexosDialog: false,
      saveDialog: false,
      addDialog: false,
      //deleteDialog: false,
      notAsignadosDialog: false,
      selectedForSave: null,
      selectedForAnexos: null,
      selectedForAsignadosMail: null,
      //selectedForDelete: null,
      filterAsignados: null,
      filterClientes: null,
      excelData: [],
      lastTicket: "no_ticket",
      menu: false,
      menu2: false,
      dateInit: null,
      dateEnd: null,
      search: "",
      searchLoading: false,
    };
  },
  methods: {
    async getData() {
      this.loading = true;
      await this.getPersonal();
      await this.getClientes();
      await this.getTasks();
      this.loading = false;
    },
    async getTasks() {
      this.loading = true;
      this.tasks = [];
      let resPromise = await fetch(this.endpoint + "tasks");
      let res = await resPromise.json();
      this.tasks = res.tasks;
      this.excelData = [];
      for (let item of this.tasks) {
        this.excelData.push({
          tarea: item.tarea,
          fecha: format(new Date(item._createdDate), "dd-MM-yyyy"),
          personalAsignado: this.resolveExcelAsignados(item),
          cliente: this.resolveExcelCliente(item),
          estatus: this.resolveExcelEstatus(item),
        });
      }
      this.tasks.sort((a, b) => {
        let no_ticket_a = a.noticket.split("-")[1];
        let no_ticket_b = b.noticket.split("-")[1];
        if (Number(no_ticket_a) > Number(no_ticket_b)) {
          return -1;
        } else if (Number(no_ticket_a) < Number(no_ticket_b)) {
          return 1;
        } else {
          return 0;
        }
      });
      if (this.tasks.length > 0) {
        this.lastTicket = this.tasks[0].noticket;
      } else {
        this.lastTicket = "no_ticket";
      }
      for(let item of this.tasks) {
        item.menu = false;
      }
      this.loading = false;
    },
    resolveExcelAsignados(item) {
      if (!item.asignados) {
        return "Sin personal asignado";
      }

      if (item.asignados.length == 0) {
      }

      let asignados = [];
      for (let persona of item.asignados) {
        let filter = this.personal.filter((item) => item._id == persona);
        if (filter.length > 0) {
          asignados.push(filter[0].nombre);
        }
      }
      let nombresString = "";
      for (let item of asignados) {
        nombresString += ` ${item}, `;
      }

      return nombresString;
    },
    resolveExcelCliente(item) {
      if (item.cliente) {
        let filter = this.clientes.filter(
          (cliente) => cliente._id == item.cliente
        );
        if (filter.length > 0) {
          return filter[0].fullName;
        }
      }
      return "Sin cliente asignado";
    },
    resolveExcelEstatus(item) {
      if (!item.estatus) {
        return "Sin estatus";
      }
      let filter = this.estatuses.filter(
        (estatus) => estatus.value == item.estatus
      );
      if (filter.length > 0) {
        return filter[0].label;
      }
      return "Sin estatus";
    },
    async getPersonal() {
      let resPromise = await fetch(this.endpoint + "staff");
      this.personal = await resPromise.json();
    },
    async getClientes() {
      let resPromise = await fetch(this.endpoint + "clients");
      let clientesRaw = await resPromise.json();
      for (let [index, item] of clientesRaw.entries()) {
        if (!item._id) {
          continue;
        }

        if (!item?.info?.name?.first && !item?.info?.company) {
          continue;
        }
        let name = "";
        if (!item?.info?.name?.first) {
          name = item?.info?.company;
        } else {
          name = item?.info?.name?.first + " " + (item?.info?.name?.last || "");
        }

        let email = "";
        if (item?.info?.emails) {
          if (item?.info?.emails.length > 0) {
            email = item?.info?.emails[0].email;
          }
        } else {
          email = null;
        }

        this.clientes.push({
          email: email,
          fullName: name,
          _id: item._id || null,
        });
      }
    },
    /*async deleteTask(id) {
      this.loading = true;
      await fetch(this.endpoint + "tasks/" + id, {
        method: "DELETE",
      });
      this.loading = false;
    },*/
    async save(item) {
      this.loading = true;
      let body = {
        asignados: item.asignados,
        cliente: item.cliente,
        estatus: item.estatus,
        noticket: item.noticket,
        _id: item._id,
        tarea: item.tarea,
        notas: item.notas || null,
        anexos: item.anexos || null,
      };
      await fetch(this.endpoint + "updateTask", {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(body),
      });
      this.getTasks();
      this.loading = false;
    },
    openSaveDialog(item) {
      this.selectedForSave = item;
      this.saveDialog = true;
    },
    openAsignadosMailDialog(item) {
      this.selectedForAsignadosMail = item;
      this.notAsignadosDialog = true;
    },
    openAnexosNotas(item) {
      this.selectedForAnexos = item;
      this.anexosDialog = true;
    },
    openDeleteDialog(item) {
      this.selectedForDelete = item;
      this.deleteDialog = true;
    },
    handleSave(data) {
      if (data.action == true) {
        this.save(data.data);
      }
      this.selectedForSave = null;
      this.saveDialog = false;
    },
    closeAnexosDialog(closed) {
      if (closed.action == true) {
        this.selectedForAnexos.notas = closed.data.notas;
        this.saveNotas(this.selectedForAnexos);
      } else {
        this.selectedForAnexos = null;
      }

      this.anexosDialog = false;
    },
    async saveNotas(item) {
      this.loading = true;
      let body = {
        asignados: item.asignados,
        cliente: item.cliente,
        estatus: item.estatus,
        _id: item._id,
        tarea: item.tarea,
        notas: item.notas || null,
        anexos: item.anexos || null,
        noticket: item.noticket,
      };
      await fetch(this.endpoint + "updateTask", {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(body),
      });
      this.getTasks();
      this.loading = false;
      this.selectedForAnexos = null;
    },
    closeAsignadosDialog() {
      this.selectedForAsignadosMail = null;
      this.notAsignadosDialog = false;
    },
    /*async delete(id) {
      this.loading = true;
      let body = {
        id: id,
      };
      await fetch(this.endpoint + "deleteTask", {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(body),
      });

      this.getTasks();
      this.loading = false;
    },
    handleDelete(data) {
      if (data.action == true) {
        this.delete(data.data._id);
      }
      this.selectedForDelete = null;
      this.deleteDialog = false;
    },*/
    handleSearchDebounce() {
      this.searchLoading = true;
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.handleSearch();
      }, 700);
    },
    async handleSearch() {
      if (this.search == null || this.search == "") {
        this.getTasks();
        return;
      }

      let resPromise = await fetch(
        this.endpoint +
          "searchTasks?" +
          new URLSearchParams({
            searchString: this.search,
          })
      );
      this.tasks = await resPromise.json();
      this.loading = false;
      this.searchLoading = false;
    },
    async filterAsignado() {
      this.loading = true;
      if (!this.filterAsignados) {
        this.getTasks();
        this.loading = false;
        return;
      }

      let body = {
        filter: "asignados",
        asignadoId: this.filterAsignados,
      };

      let resPromise = await fetch(
        this.endpoint +
          "tasksFilter?" +
          new URLSearchParams({
            pageSize: this.itemsPerPage,
            page: this.page,
          }),
        {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
          },
          body: JSON.stringify(body),
        }
      );
      this.tasks = await resPromise.json();
      this.loading = false;
    },
    async filterCliente() {
      this.loading = true;
      if (!this.filterClientes) {
        this.getTasks();
        this.loading = false;
        return;
      }

      let body = {
        filter: "clientes",
        clienteId: this.filterClientes,
      };

      let resPromise = await fetch(
        this.endpoint +
          "tasksFilter?" +
          new URLSearchParams({
            pageSize: this.itemsPerPage,
            page: this.page,
          }),
        {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
          },
          body: JSON.stringify(body),
        }
      );
      this.tasks = await resPromise.json();
      this.loading = false;
    },
    resolveReporteData(item) {
      let body = {
        tarea: item.tarea,
        fecha: format(new Date(item._createdDate), "dd-MM-yyyy"),
        nombre_cliente: this.resolveExcelCliente(item),
        asignados: this.resolveExcelAsignados(item),
        no_reporte: item.noticket || "Sin asignar",
        notas: convert(item.notas, {
          wordwrap: 130,
        }),
      };
      return body;
    },
    openAddDialog() {
      if (this.tasks.length > 0) {
        this.lastTicket = this.tasks[0].noticket;
      } else {
        this.lastTicket = "no_ticket";
      }
      this.addDialog = true;
    },
    async handleAdd(closed) {
      if (closed.action == true) {
        this.loading = true;
        await fetch(this.endpoint + "addTask", {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
          },
          body: JSON.stringify(closed.data),
        });
        this.getTasks();
      }
      this.addDialog = false;
    },
    async filtroFecha() {
      this.loading = true;
      this.tasks = [];
      let resPromise = await fetch(this.endpoint + "tasks_filter_date", {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify({
          fechaInicio: new Date(this.dateInit),
          fechaFin: new Date(this.dateEnd),
        }),
      });
      this.tasks = await resPromise.json();
      this.excelData = [];
      for (let item of this.tasks) {
        this.excelData.push({
          tarea: item.tarea,
          fecha: format(new Date(item._createdDate), "dd-MM-yyyy"),
          personalAsignado: this.resolveExcelAsignados(item),
          cliente: this.resolveExcelCliente(item),
          estatus: this.resolveExcelEstatus(item),
        });
      }
      this.loading = false;
    },
    log(reporte, index) { 
        this.tasks[index].menu = false;
        this.tasks[index].fechaDeAtencion = reporte.fechaDeAtencion + ' 00:00';
    }
  },
  created() {
    this.getData();
  },
};
</script>

<style>
td {
  vertical-align: middle;
}
</style>