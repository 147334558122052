<template>
  <v-dialog v-model="dialog_ref" max-width="768" hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-card-title class="text-h5 blue white--text">
        Notificar asignados por correo
        <v-spacer></v-spacer>
        <v-btn icon outlined class="white--text" @click="emitClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container fluid>
        <h3>Correos que serán notificados</h3>
        <v-row>
          <v-col>
            <v-list-item v-for="item in correos" :key="item">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="6">
            <v-btn color="danger" @click="emitClose()"> CANCELAR </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" @click="confirm()"> NOTIFICAR ASIGNADOS </v-btn>
            <v-btn color="primary" @click="confirmCliente()"> NOTIFICAR AL CLIENTE </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog_ref: Boolean,
    data: Object,
  },
  data() {
    return {
      correos: [],
      endpoint: "https://megacomp.mx/_functions/",
    };
  },
  methods: {
    emitClose() {
      this.$emit("onClose", {
        action: false,
        data: null,
      });
    },
    async confirm() {
      let cliente = null;
      let filterCliente = this.data.clientes.filter(
        (cliente) => cliente._id == this.data.tarea.cliente
      );
      if (filterCliente.length > 0) {
        cliente = filterCliente[0].fullName;
      }

      let body = {
        sendTo: this.correos,
        noticket: this.data.tarea.noticket,
        tarea: this.data.tarea.tarea,
        cliente: cliente,
      };

      let resPromise = await fetch(this.endpoint + "notifyAsignados", {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(body),
      });

      let res = await resPromise.json();

      this.$emit("onClose", {
        action: true,
        data: this.data,
      });
    },
    async confirmCliente() {
      let cliente = null;
      let correoCliente = null;
      let filterCliente = this.data.clientes.filter(
        (cliente) => cliente._id == this.data.tarea.cliente
      );
      if (filterCliente.length > 0) {
        cliente = filterCliente[0].fullName;
        correoCliente = filterCliente[0].email;
      }

      if (!correoCliente) {
        alert('Este cliente no tiene correo registrado');
        return;
      }

      let body = {
        sendTo: correoCliente,
        noticket: this.data.tarea.noticket,
        tarea: this.data.tarea.tarea,
        cliente: cliente,
      };

      let resPromise = await fetch(this.endpoint + "notifyCliente", {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(body),
      });

      let res = await resPromise.json();

      this.$emit("onClose", {
        action: true,
        data: this.data,
      });
    },
  },
  watch: {
    data(newVal) {
      // Dialog closed
      if (newVal == null || newVal.tarea == null) {
        return;
      }
      // Dialog opened
      let personal = this.data.personal;
      let task = this.data.tarea;
      this.correos = [];
      for (let item of task.asignados) {
        let mailArray = personal.filter((persona) => persona._id == item);
        if (mailArray.length > 0) {
          let correo = mailArray[0].correo;
          this.correos.push(correo);
        }
      }
    },
  },
};
</script>