import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { format } from 'date-fns';
import { es } from 'date-fns/locale'
import excel from 'vue-excel-export';
import VTooltip from 'v-tooltip'

Vue.config.productionTip = false;

Vue.use(VTooltip);

Vue.filter('date', function(value) {
  if (value) {
    let formatted = format(new Date(value), 'dd-MMM-yyyy', {locale: es});
    return formatted;
  }
});

import wysiwyg from "vue-wysiwyg";

Vue.use(wysiwyg, {
  hideModules: {
    'image': true
  }
});

Vue.use(excel);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
