<template>
  <v-dialog
    v-model="dialog_ref"
    max-width="768"
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="text-h5 blue white--text">
        Confirmacion de eliminación
        <v-spacer></v-spacer>
        <v-btn icon outlined class="white--text" @click="emitClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col>
            <h4>
              {{ text }}
            </h4>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="6">
            <v-btn color="danger" @click="emitClose()"> CANCELAR </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" @click="confirm()"> CONFIRMAR </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog_ref: Boolean,
    data: Object,
  },
  data() {
    return {
      text: "Ésta acción no puede deshacerse. ¿Desea continuar?",
    };
  },
  methods: {
    emitClose() {
      this.$emit("onClose", {
        action: false,
        data: null,
      });
    },
    confirm() {
      this.$emit("onClose", {
        action: true,
        data: this.data,
      });
    },
  }
};
</script>